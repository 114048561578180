
import VueRouter from 'vue-router';
import Editor from '../views/Home2.vue';
import { API } from 'aws-amplify';
import { Auth } from 'aws-amplify';
import store from '../store'
import { CognitoUser, CognitoRefreshToken } from 'amazon-cognito-identity-js';

const routes = [
    {
      path: "/",
      name: "/",
      redirect: "/home",
    },
    {
      path: "/home",
      name: "home",
      beforeEnter: checkLogged,
      component: Editor
      // component: () => import(/* webpackChunkName: "home" */ '../views/Login.vue'),
    },
    {
      path: "/viewer/:project",
      name: "viewer",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "viewer" */ '../views/Viewer.vue'),
      props: true
    },
    {
      path: "/payment",
      name: "payment",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "viewer" */ '../views/Payment.vue'),
    },
    {
      path: "/profile",
      name: "profile",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    },
    {
      path: "/assets",
      name: "assets",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "profile" */ '../views/Assets.vue'),
    },
    {
        path: "/login",
        name: "login",
        beforeEnter: skipLogged,
        component: () => import(/* webpackChunkName: "login" */ '../views/SignIn.vue'),
    },
    {
      path: "/projects",
      name: "projects",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
    },
    {
      path: '/admin',
      name: 'Admin',
      beforeEnter: checkAdminRights,
      component: () =>
          import(/* webpackChunkName: "market" */ '../views/Admin.vue'),
    },
    {
      path: '/license',
      name: 'License',
      beforeEnter: checkAdminRights,
      component: () =>
          import(/* webpackChunkName: "market" */ '../views/License.vue'),
    },
    {
      path: '/licenses',
      name: 'Licenses',
      beforeEnter: checkAdminRights,
      component: () =>
          import(/* webpackChunkName: "market" */ '../views/Licenses.vue'),
    },
    {
      path: "/docs",
      name: "docs",
      beforeEnter(){window.open("https://docs.rplab.online");}
    },
    {
      path: "/pricing",
      name: "pricing",
      component: () =>
          import(/* webpackChunkName: "market" */ '../views/Pricing.vue'),
    },
    {
      path: "/addon/:feature_id",
      name: "addon",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "viewer" */ '../views/AddOn.vue'),
      props: true
    },
    {
      path: "/subscriptions",
      name: "subscriptions",
      beforeEnter: guardLogged,
      component: () => import(/* webpackChunkName: "viewer" */ '../views/Subscriptions.vue')
    },
    
]
async function checkLogged(to, from, next) {
 

  try 
    {     
      var user = await Auth.currentAuthenticatedUser();
      console.log('Logged in '+user.attributes.email) 
      //mapMutations(["setLoggedIn"])(true);
      store.commit('setLoggedIn',true)
      next();
    }   
    catch(e) 
    {  
      store.commit('setLoggedIn',false)
      next();   
    } 
  
}

async function guardLogged(to, from, next) {
 
  try 
    { 
      const user = await Auth.currentAuthenticatedUser();
      console.log('Logged in '+user.attributes.email)
      store.state.email = user.attributes.email;
      //mapMutations(["setLoggedIn"])(true);
      store.commit('setLoggedIn',true)
      next();
    }   
  catch(e) 
  {  
    console.log(e);
    store.commit('setLoggedIn',false)
    store.state.email = undefined;
    next("/login");   
  } 
  
}

async function skipLogged(to, from, next) {

  
  try 
    {     
      var user = await Auth.currentAuthenticatedUser();
      console.log('Logged in '+user.attributes.email) 
      //mapMutations(["setLoggedIn"])(true);
      store.commit('setLoggedIn',true)
      next('/home');
    }   
    catch(e) 
    {  
      store.commit('setLoggedIn',false)
      next();   
    } 
  
}

async function checkAdminRights(to, from, next) {
   
  const profile = await API.get('rpgUserProfile','/profile');
  let userIsAdmin = profile.isAdmin;
  if(userIsAdmin) 
  {
    store.state.isAdmin=true;
    next();
  }
  else
  {
    store.state.isAdmin=false;
    next("/");
  }
}



// Function to refresh tokens using refresh token
async function refreshTokensWithCognitoSdk(refreshTokenString, email) {
  Auth.userPool.clientId = '1as8ft89r4ast9gj3ef6knlthv';
  // const poolData = {
  //   UserPoolId: 'ap-northeast-2_pCCmMMx9s', // Your user pool id here
  //   ClientId: '1as8ft89r4ast9gj3ef6knlthv', // Your client id here
  //   Region: 'ap-northeast-2'
  // };
  // const userPool = new CognitoUserPool(poolData);
  const userPool = Auth.userPool;
  const userData = {
    Username: email, // The username of the user
    Pool: userPool
  };

  const cognitoUser = new CognitoUser(userData);
  const refreshToken = new CognitoRefreshToken({ RefreshToken: refreshTokenString });
  try {
    const session = await new Promise((resolve, reject) => {
        cognitoUser.refreshSession(refreshToken, (err, session) => {
            if (err) {
                reject(err);
            } else {
                resolve(session);
            }
        });
    });

    // Access the new tokens from the session object
    const idToken = session.getIdToken().getJwtToken();
    const accessToken = session.getAccessToken().getJwtToken();
    const newRefreshToken = session.getRefreshToken().getToken();

    console.log('Session refreshed successfully!');
    console.log('ID Token:', idToken);
    console.log('Access Token:', accessToken);
    console.log('New Refresh Token:', newRefreshToken);
    
    // Auth.currentCredentials = async()=>session;
    // Auth.currentUserPoolUser = async()=>cognitoUser;
    // console.log(await Auth.currentSession());
    // Return the session or any relevant data
    return session;
  } catch (error) {
      console.error('Error refreshing session:', error);
      // Handle the error appropriately
      throw error;
  }
    
  
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});
  

// Define the function you want to run before rendering App
async function preRenderFunction(to, from, next) {

  const token = localStorage.getItem('authToken');
  const email = localStorage.getItem('authEmail');

  if(token)
    await refreshTokensWithCognitoSdk(token,email);
  next() // Continue to the route
}

// Add the beforeEach navigation guard
router.beforeEach(preRenderFunction)

  export default router;