
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App2.vue'
import router from './router'
import VueRouter from 'vue-router';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports'; 
Amplify.configure(awsconfig);
import 'vue-spinners/dist/vue-spinners.css'
import VueSpinners from 'vue-spinners/dist/vue-spinners.common'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import SoftUIDashboard from "./soft-ui-dashboard";
import {BootstrapVue, IconsPlugin  } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
 import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from "./store";
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

import VuejsDialog from 'vuejs-dialog';
//import VuejsDialogMixin from 'vuejs-dialog/dist/vuejs-dialog-mixin.min.js'; // only needed in custom components
// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

// Tell Vue to install the plugin.
Vue.use(VuejsDialog);
var PulseLoader = require('vue-spinner/src/PulseLoader.vue');
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import {faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import {faWindows } from '@fortawesome/free-brands-svg-icons'
import {faUnity } from '@fortawesome/free-brands-svg-icons'
import {faUser} from '@fortawesome/free-solid-svg-icons'
import {faRightToBracket} from '@fortawesome/free-solid-svg-icons'
import {faGear} from '@fortawesome/free-solid-svg-icons'
import {faClose} from '@fortawesome/free-solid-svg-icons'
import {faPerson} from '@fortawesome/free-solid-svg-icons'
import {faPanorama} from '@fortawesome/free-solid-svg-icons'
import {faImages} from '@fortawesome/free-solid-svg-icons'
import {faAngleLeft} from '@fortawesome/free-solid-svg-icons'
import {faAngleDown} from '@fortawesome/free-solid-svg-icons'
import {faAngleUp} from '@fortawesome/free-solid-svg-icons'
import {faPuzzlePiece} from '@fortawesome/free-solid-svg-icons'
import {faGlobe} from '@fortawesome/free-solid-svg-icons'
import {faFaceLaugh} from '@fortawesome/free-solid-svg-icons'
import {faCameraRetro} from '@fortawesome/free-solid-svg-icons'
import {faRotate} from '@fortawesome/free-solid-svg-icons'
import {faArrowsUpDownLeftRight} from '@fortawesome/free-solid-svg-icons'
import {faCompress} from '@fortawesome/free-solid-svg-icons'
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import {faPen} from '@fortawesome/free-solid-svg-icons'
import {faFloppyDisk} from '@fortawesome/free-solid-svg-icons'
import {faPause} from '@fortawesome/free-solid-svg-icons'
import {faPlay} from '@fortawesome/free-solid-svg-icons'
import {faStop} from '@fortawesome/free-solid-svg-icons'
import {faClone} from '@fortawesome/free-solid-svg-icons'
import {faVideo} from '@fortawesome/free-solid-svg-icons'
import {faUpDown} from '@fortawesome/free-solid-svg-icons'
import {faLocationPin} from '@fortawesome/free-solid-svg-icons'
import {faLocationDot} from '@fortawesome/free-solid-svg-icons'
import {faCircleDollarToSlot} from '@fortawesome/free-solid-svg-icons'
import {faHourglassHalf} from '@fortawesome/free-solid-svg-icons'
import {faClapperboard} from '@fortawesome/free-solid-svg-icons'
import {faCloudArrowDown} from '@fortawesome/free-solid-svg-icons'
import {faUserAstronaut} from '@fortawesome/free-solid-svg-icons'
import {faUnreal} from "./components/Icon/UnrealIcon"
import {faBook} from '@fortawesome/free-solid-svg-icons'
import {faTag} from '@fortawesome/free-solid-svg-icons'
import { faRotateRight } from '@fortawesome/free-solid-svg-icons';
/* add icons to the library */
library.add(faGooglePlay)
library.add(faWindows)
library.add(faUnity)
library.add(faUser)
library.add(faGear)
library.add(faClose)
library.add(faRightToBracket)
library.add(faPerson)
library.add(faPanorama)
library.add(faImages)
library.add(faAngleLeft)
library.add(faAngleDown)
library.add(faAngleUp)
library.add(faRotate)
library.add(faArrowsUpDownLeftRight)
library.add(faCompress)
library.add(faTrash)
library.add(faPen)
library.add(faFloppyDisk)
library.add(faPause)
library.add(faPlay)
library.add(faStop)
library.add(faClone)
library.add(faVideo)
library.add(faUpDown)
library.add(faLocationPin)
library.add(faLocationDot)
library.add(faCircleDollarToSlot)
library.add(faHourglassHalf)
library.add(faClapperboard)
library.add(faCloudArrowDown)
library.add(faUserAstronaut)
library.add(faUnreal);
library.add(faBook);
library.add(faTag);
library.add(faRotateRight)
library.add(faPuzzlePiece)
library.add(faGlobe)
library.add(faCameraRetro)
library.add(faFaceLaugh)
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(VueRouter)
Vue.use(PulseLoader)
Vue.use(VueSpinners)
Vue.config.productionTip = false
Vue.use(Vuex);
Vue.use(Loading)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.mixin({
  data: function() 
  {
    return {
      logged:true,
      
    }
  }
})

new Vue({
  router,
  store,
  SoftUIDashboard,
  render: h => h(App)
}).$mount('#app')


